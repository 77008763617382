.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .formContainer {
    text-align: center;

    .formWrapper {
      .logoContainer {
        display: flex;
        align-items: center;

        .logoImage {
          width: 250px;
          height: 250px;
        }

        .logoText {
          font-family: 'Raleway', sans-serif;
          font-size: 70px;
          margin-top: -5px;

          .logo {
            font-size: 100px;
            font-weight: bold;
            color: orangered;
            font-family: 'Raleway', sans-serif;
            position: relative;

            &:after {
              content: attr(data-text);
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              color: black;
              -webkit-text-stroke: 4px black;
              font-size: inherit;
              font-weight: inherit;
              font-family: inherit;
              opacity: 0.4;
            }
          }
        }
      }

      .subtitleContainer {
        margin-top: 10px;

        .subtitle {
          font-size: 20px;
          font-family: 'Raleway', sans-serif;
          color: white;
          font-weight: 400;
          text-align: center;
          margin-top: -3px;
        }
      }

      .button-Container {
        display:flex;
        align-items: center;
        justify-content: center;
        width: 800px;
        margin-top: 20px;
        //   height: 40px;

        .payment-Button {
                  align-items: center;
        justify-content: center;
          background-color: #FE5400;
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 44px;
          cursor: pointer;
          font-weight: 600;
          font-size: 25px;
          margin-top: 20px;
          width: 400px;
          height: 40px;
          font-family: "ClashDisplay-Semibold", sans-serif;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.3s ease; /* Add transition for box-shadow */

          &:hover {
              box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow on hover */
              color: black;
          }
        }
      }

      .changeNumberContainer {
        margin-top: 20px;

        span {
          color: white;
          cursor: pointer;

          .link {
            text-decoration: underline;
            color: inherit;

            &:hover {
              color: #fe5400;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1080px) {
    .formContainer {
      .formWrapper {
        .logoContainer {
          .logoImage {
            width: 180px;
            height: 180px;
          }

          .logoText {
            .logo {
              font-size: 80px;
            }
          }
        }

        .subtitleContainer {
          .subtitle {
            font-size: 18px;
          }
        }

        .buttonsContainer {
          .payButton {
            width: 200px;
            font-size: 24px;
          }
        }
      }
    }
  }

  @media (max-width: 998px) {
    .formContainer {
      .formWrapper {
        .logoContainer {
          .logoImage {
            width: 150px;
            height: 150px;
          }

          .logoText {
            .logo {
              font-size: 70px;
            }
          }
        }

        .subtitleContainer {
          .subtitle {
            font-size: 16px;
          }
        }

        .buttonsContainer {
          .payButton {
            width: 180px;
            font-size: 22px;
          }
        }
      }
    }
  }

  @media (max-width: 660px) {
    .formContainer {
      .formWrapper {
        .logoContainer {
          .logoImage {
            width: 120px;
            height: 120px;
          }

          .logoText {
            .logo {
              font-size: 50px;
            }
          }
        }

        .subtitleContainer {
          .subtitle {
            font-size: 14px;
          }
        }

        .buttonsContainer {
          .payButton {
            width: 180px;
            font-size: 20px;
          }
        }
        .changeNumberContainer{
          span{
            font-size:12px;
          }
        }
      }
    }
  }
}
