
/* app.scss */

/**
 * @license
 *
 * Font Family: Clash Display
 * Designed by: Indian Type Foundry
 * URL: https://www.fontshare.com/fonts/clash-display
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Clash Display Variable(Variable font)
 * Clash Display Extralight
 * Clash Display Light
 * Clash Display Regular
 * Clash Display Medium
 * Clash Display Semibold
 * Clash Display Bold
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
    font-family: 'ClashDisplay-Variable';
    src: url('/public/fonts/ClashDisplay-Variable.woff2') format('woff2'),
         url('/public/fonts/ClashDisplay-Variable.woff') format('woff'),
         url('/public/fonts/ClashDisplay-Variable.ttf') format('truetype');
         font-weight: 200 700;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'ClashDisplay-Extralight';
    src: url('/public/fonts/ClashDisplay-Extralight.woff2') format('woff2'),
         url('/public/fonts/ClashDisplay-Extralight.woff') format('woff'),
         url('/public/fonts/ClashDisplay-Extralight.ttf') format('truetype');
         font-weight: 200;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'ClashDisplay-Light';
    src: url('/public/fonts/ClashDisplay-Light.woff2') format('woff2'),
         url('/public/fonts/ClashDisplay-Light.woff') format('woff'),
         url('/public/fonts/ClashDisplay-Light.ttf') format('truetype');
         font-weight: 300;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'ClashDisplay-Regular';
    src: url('/public/fonts/ClashDisplay-Regular.woff2') format('woff2'),
         url('/public/fonts/ClashDisplay-Regular.woff') format('woff'),
         url('/public/fonts/ClashDisplay-Regular.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'ClashDisplay-Medium';
    src: url('/public/fonts/ClashDisplay-Medium.woff2') format('woff2'),
         url('/public/fonts/ClashDisplay-Medium.woff') format('woff'),
         url('/public/fonts/ClashDisplay-Medium.ttf') format('truetype');
         font-weight: 500;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'ClashDisplay-Semibold';
    src: url('/public/fonts/ClashDisplay-Semibold.woff2') format('woff2'),
         url('/public/fonts/ClashDisplay-Semibold.woff') format('woff'),
         url('/public/fonts/ClashDisplay-Semibold.ttf') format('truetype');
         font-weight: 600;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'ClashDisplay-Bold';
    src: url('/public/fonts/ClashDisplay-Bold.woff2') format('woff2'),
         url('/public/fonts/ClashDisplay-Bold.woff') format('woff'),
         url('/public/fonts/ClashDisplay-Bold.ttf') format('truetype');
         font-weight: 700;
         font-display: swap;
         font-style: normal;
  }
  

/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: 'Satoshi-Variable';
  src: url('/public/fonts/Satoshi-Variable.woff2') format('woff2'),
       url('/public/fonts/Satoshi-Variable.woff') format('woff'),
       url('/public/fonts/Satoshi-Variable.ttf') format('truetype');
       font-weight: 300 900;
       font-display: swap;
       font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: 'Satoshi-VariableItalic';
  src: url('/public/fonts/Satoshi-VariableItalic.woff2') format('woff2'),
       url('/public/fonts/Satoshi-VariableItalic.woff') format('woff'),
       url('/public/fonts/Satoshi-VariableItalic.ttf') format('truetype');
       font-weight: 300 900;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Light';
  src: url('/public/fonts/Satoshi-Light.woff2') format('woff2'),
       url('/public/fonts/Satoshi-Light.woff') format('woff'),
       url('/public/fonts/Satoshi-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-LightItalic';
  src: url('/public/fonts/Satoshi-LightItalic.woff2') format('woff2'),
       url('/public/fonts/Satoshi-LightItalic.woff') format('woff'),
       url('/public/fonts/Satoshi-LightItalic.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Regular';
  src: url('/public/fonts/Satoshi-Regular.woff2') format('woff2'),
       url('/public/fonts/Satoshi-Regular.woff') format('woff'),
       url('/public/fonts/Satoshi-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-Italic';
  src: url('/public/fonts/Satoshi-Italic.woff2') format('woff2'),
       url('/public/fonts/Satoshi-Italic.woff') format('woff'),
       url('/public/fonts/Satoshi-Italic.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Medium';
  src: url('/public/fonts/Satoshi-Medium.woff2') format('woff2'),
       url('/public/fonts/Satoshi-Medium.woff') format('woff'),
       url('/public/fonts/Satoshi-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('/public/fonts/Satoshi-MediumItalic.woff2') format('woff2'),
       url('/public/fonts/Satoshi-MediumItalic.woff') format('woff'),
       url('/public/fonts/Satoshi-MediumItalic.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Bold';
  src: url('/public/fonts/Satoshi-Bold.woff2') format('woff2'),
       url('/public/fonts/Satoshi-Bold.woff') format('woff'),
       url('/public/fonts/Satoshi-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BoldItalic';
  src: url('/public/fonts/Satoshi-BoldItalic.woff2') format('woff2'),
       url('/public/fonts/Satoshi-BoldItalic.woff') format('woff'),
       url('/public/fonts/Satoshi-BoldItalic.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Black';
  src: url('/public/fonts/Satoshi-Black.woff2') format('woff2'),
       url('/public/fonts/Satoshi-Black.woff') format('woff'),
       url('/public/fonts/Satoshi-Black.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BlackItalic';
  src: url('/public/fonts/Satoshi-BlackItalic.woff2') format('woff2'),
       url('/public/fonts/Satoshi-BlackItalic.woff') format('woff'),
       url('/public/fonts/Satoshi-BlackItalic.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: italic;
}


  
/* app.scss */

*{
    .link{
        text-decoration:none;
        color:inherit;
    }
}

body,html{
    min-height:100%;
}

body {
    background-color: #1D1D1D; /* Set the background color */
    /* font-family: Arial, sans-serif; */
  }
  

.app {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    min-height: 100vh; /* Ensure app takes up at least viewport height */
  }
  /* You can add more global styles here if needed */


*{
    .link{
        text-decoration:none;
        color:inherit;
    }
}

body,html{
    min-height:100%;
}

body {
    background-color: #1D1D1D; /* Set the background color */
    /* font-family: Arial, sans-serif; */
  }
  

.app {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    min-height: 100vh; /* Ensure app takes up at least viewport height */
  }
  /* You can add more global styles here if needed */
  