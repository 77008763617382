.block2 {
  background-color: #1D1D1D;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 357px;
  margin-top: 80px;

  .title {
    color: #C2C2C2;
    font-size: 56px;
    margin-bottom: 50px;
    font-weight: bold;
    font-family: "ClashDisplay-Semibold", sans-serif;
  }

  .boxes {
    display: flex;
    gap: 20px;

    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
      width: 170px;
      height: 170px;
      flex: 1;
      background-color: transparent;
      border: 1px solid #fe5400;
      border-radius: 16px;
      padding: 10px;
      transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Add box-shadow to the transition */

      &:hover {
        background-color: #272727; /* Opposite color glow */
        box-shadow: 0 0 10px rgba(39, 39, 39, 0.8); /* Opposite color glow */
      }

      p {
        font-size: 16px;
        margin: 0px;
        font-family: 'ClashDisplay-Regular';
      }
    }
  }
}

@media (max-width: 1080px) {
  .block2 .title {
    font-size: 32px; /* Adjust font size for smaller screens */
    margin-bottom: 40px; /* Adjust margin for smaller screens */
  }

  .block2 .boxes {
    gap: 15px; /* Reduce gap between boxes */

    .box {
      width: 150px; /* Adjust box width for smaller screens */
      height: 150px; /* Adjust box height for smaller screens */
    }
  }
}

@media (max-width: 998px) {
  .block2 {
    margin-bottom: 10px;
  }

  .block2 .title {
    font-size: 50px; /* Adjust font size for even smaller screens */
    margin-bottom: 30px; /* Adjust margin for even smaller screens */
  }

  .block2 .boxes {
    padding: 0px 20px;
    flex-wrap: wrap; /* Allow boxes to wrap onto a new line */
    justify-content: center; /* Center boxes horizontally */
    gap: 10px; /* Gap between boxes */

    .box {
      width: calc(50% - 10px); /* Boxes take half of the container's width minus gap */
      height: 150px; /* Keep a fixed height */
    }
  }
}

@media (max-width: 660px) {
  .block2 {
    margin-bottom: 50px;
    justify-content: center;
    margin-bottom: 50px;
  }

  .block2 .title {
    font-size: 45px;
    margin: 20px 0px;
    padding-left: 10px;
  }

  .block2 .boxes {
    flex-direction: column;
    gap: 10px;

    .box {
      width: 100%;
      height: auto;
    }
  }
}
