.success{
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Set the height to 100% of the viewport height */

    .container{
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        gap:2px;

        .logoContainer {
            display: flex;
            // flex-direction: column;
            align-items: center;
      
            .logo {
              display: flex;
              justify-content: center;
              align-items: center;
      
              .logoImage {
                width: 250px;
                height: 250px;
              }
            }
      
            .logoText {
              font-family: 'Raleway', sans-serif;
              font-size: 70px;
              margin-top: -5px;
      
              .maintitle {
                font-size: 100px;
                font-weight: bold;
                color: orangered;
                font-family: 'Raleway', sans-serif;
                position: relative;
      
                &:after {
                  content: attr(data-text);
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: -1;
                  color: black;
                  -webkit-text-stroke: 4px black;
                  font-size: inherit;
                  font-weight: inherit;
                  font-family: inherit;
                  opacity: 0.4;
                }
              }
            }
          }

          .description{
                display:flex;
                flex-direction:column;
                align-items: center;
                justify-content: center;
                // gap:2px;

            h2{
                color:white;
                font-size:28px;
                margin-bottom: 4px;
                font-family:"Raleway",sans-serif;
    
            }
            p{   
                align-items:center;
                    justify-content:center;
                color:white;
                font-size:20px;
                font-family:"Raleway",sans-serif;
    
            }
            .buttonStyle{
                background-color: #FE5400;
                color: white;
                border: none;
                padding: 10px 20px;
                border-radius: 44px;
                cursor: pointer;
                font-weight: 600;
                font-size: 25px;
                margin-top: 20px;
                width: 400px;
                height: 60px;
                font-family: "ClashDisplay-Semibold", sans-serif;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                transition: box-shadow 0.3s ease; /* Add transition for box-shadow */

                &:hover {
                    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow on hover */
                    color: black;
                }
    
            }
        }

          
    }
}

@media (max-width:1080px){
    .success{
        .container{
            .logoContainer{
                .logo{
                    .logoImage{
                        width:200px;
                        height:200px;
                    }
                }
                .logoText{
                    .maintitle{
                        font-size:90px;
                    }
                }
            }

            .description{

                h2{

                }

                p{

                }

                .buttonStyle{
                }
            }
        }
    }
}

@media (max-width:998px){
    .success{
        .container{
            .logoContainer{
                .logo{
                    .logoImage{
                        width: 180px;
                        height:180px;
                    }
                }
                .logoText{
                    .maintitle{
                       font-size:80px;

                    }
                }
            }

            .description{

                h2{

                }

                p{

                }

                .buttonStyle{
                    width:400px;
                    height:50px;
                    font-size:20px;
                }
            }
        }
    }
    
}

@media (max-width:660px){
    .success{
        .container{
            .logoContainer{
                .logo{
                    .logoImage{
                        width:100px;
                        height:100px;
                    }
                }
                .logoText{
                    .maintitle{
                      font-size:40px;
                    }
                }
            }

            .description{

                h2{
                    font-size:20px;
                }

                p{   
                    text-align:center;
                    
                    font-size:16px;
                }

                .buttonStyle{
                    width:300px;
                    height:50px;
                    font-size:20px;
                }
            }
        }
    }
    
}