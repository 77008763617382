.signup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .container {
    .logoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;

        .logoImage {
          width: 250px;
          height: 250px;
        }
      }

      .logoText {
        font-family: 'Raleway', sans-serif;
        font-size: 70px;
        margin-top: -5px;

        .maintitle {
          font-size: 100px;
          font-weight: bold;
          color: orangered;
          font-family: 'Raleway', sans-serif;
          position: relative;

          &:after {
            content: attr(data-text);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            color: black;
            -webkit-text-stroke: 4px black;
            font-size: inherit;
            font-weight: inherit;
            font-family: inherit;
            opacity: 0.4;
          }
        }
      }
    }

    .subtitlesContainer {
      margin-top: 20px;

      .subtitle {
        font-size: 20px;
        font-family: 'Raleway', sans-serif;
        color: white;
      }
    }

    .formContainer {
      margin-top: 20px;
    
      .form-input {
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .inputGroup {
          display: flex;
          width: 100%;
    
          select,
          input[type="tel"] {
            flex-grow: 1;
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 16px;
            color: white;
            background-color: #f5f5f546;
          }
    
          select {
            margin-right: 10px;
            width: 120px;
            color: #fe5400 // adjust this value as per your design needs
          }

          input[type="tel"] {
            color: white;
          }
        }

        .note {
          text-align:center;
          font-size: 14px;
          color: #777;
        }

        .button {
          background-color: #FE5400;
                color: white;
                border: none;
                padding: 10px 20px;
                border-radius: 44px;
                cursor: pointer;
                font-weight: 600;
                font-size: 25px;
                margin-top: 20px;
                width: 400px;
                height: 60px;
                font-family: "ClashDisplay-Semibold", sans-serif;
                box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                transition: box-shadow 0.3s ease; /* Add transition for box-shadow */

                &:hover {
                    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow on hover */
                    color: black;
                }
        }
      }
    }
  }

  @media (max-width: 1080px) {
    .container {
      .logoContainer {
        .logo {
          .logoImage {
            width: 200px;
            height: 200px;
          }
        }
        .logoText {
          .maintitle {
            font-size: 90px;
          }
        }
      }

      .subtitlesContainer {
        .subtitle {
          font-size: 20px;
        }
      }

      .formContainer {
        .form-input {
          width: 100%;

          p {
            font-size: 10px;
          }

          .button {
            width: 200px;
            height: 60px;
            font-size: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 998px) {
    .container {
      .logoContainer {
        .logo {
          .logoImage {
            width: 180px;
            height: 180px;
          }
        }
        .logoText {
            .maintitle {
              font-size: 80px;
            }
          }
      }

      .subtitlesContainer {
        .subtitle {
          font-size: 16px;
        }
      }

      .formContainer {
        .form-input {
          .button {
            width: 180px;
            height: 45px;
            font-size: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 660px) {
    .container {
      .logoContainer {
        .logo {
          .logoImage {
            width: 125px;
            height: 125px;
          }
        }
        .logoText {
            .maintitle {
              font-size: 40px;
            }
          }
      }

      .subtitlesContainer {
        .subtitle {
          font-size: 14px;
        }
      }

      .formContainer {
        .form-input {
          .button {
            width: 200px;
            height: 50px;
            font-size: 20px;
          }
        }
      }
    }
  }
}
