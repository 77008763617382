// CONTAINER2.scss
.slider {
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1450px;
    margin: 0 50px;
    padding: 20px;
    // gap:20px;

    .left {
      flex: 1;
      text-align: center;

      .slider-button.prev-button {
        background-color: #FE5400;
        color: #fff;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;
        font-family: 'ClashDisplay-Semibold', sans-serif;
        margin-right: 20px; // Add margin to both buttons
        height: 40px; // Add a fixed height to both buttons
        width: 120px; // Add a fixed width to both buttons
        margin-right:70px;
        &:hover {
          color: black;
        }
      }
    }

    .right {
      flex: 1;
      text-align: center;

      .slider-button.next-button {
        background-color: #FE5400;
        color: #fff;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;
        font-family: 'ClashDisplay-Semibold', sans-serif;
        margin-right: 20px; // Add margin to both buttons
        height: 40px; // Add a fixed height to both buttons
        width: 120px; // Add a fixed width to both buttons

        &:hover {
          color: black;
        }
      }
    }

    .mid {
      flex: 3;
      text-align: center;
      position: relative;
      gap: 10px;

      .content {
        display: flex;
        align-items: center;
        color: white;

        .version-div {
          display: flex;
          align-items: center;
          transform: rotate(-90deg);
          transform-origin: left bottom;
          white-space: nowrap;
          position: absolute;
          top: 75%;
          font-size: 50px;
          font-weight: bold;
          color: white;
          width: 120px;
          height: auto;
          margin-right: -25px;
          font-family: 'ClashDisplay-Bold', sans-serif;
        }

        .image-container {
          text-align: center;

          img {
            width: 386px;
            height: 688px;
            display: block;
            margin: 0 auto;
          }
        }

        .description {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
          width: 400px;
          height: 800px;
          gap: 5px;

          .image-title {
            font-size: 42px;
            font-family: 'ClashDisplay-Semibold', sans-serif;
          }

          .subtext-div {
            color: #ffba08;
            font-family: 'Satoshi-Regular', sans-serif;
          }

          ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            text-align: left;
            font-family: 'Satoshi-Regular', sans-serif;

            li {
              margin-bottom: 10px;
            }
          }
        }
      }

      .indicator-buttons {
        display: flex;
        justify-content: center;
        margin-top: -50px;

        .indicator-button {
          background-color: #6f6f6f;
          border: none;
          width: 10px;
          height: 10px;
          margin: 0 5px;
          cursor: pointer;
          border-radius: 50%;
          transition: background-color 0.3s ease;

          &.active {
            background-color: #FE5400;
          }
        }
      }
    }
  }

  .mobile-version {
    .left,
    .right,
    .mid {
      display: none; // Hide the left, right, and mid components
    }
  }
}

// @media (max-width:1080px){
//   .slider{
//     .container{
//       .left{

//       }
//       .mid{

//       }
//       .right{

//       }
//     }
//   }
// }

@media (max-width: 1160px) {
.slider{
    .container {
    max-width: 990px; /* Adjust max-width for screens up to 1080px */
    justify-content:center;
    align-items: center;
    margin:0 50px;

    .mid {
    // gap:4px;
    justify-content:center;
    align-items: center;
    .content {
      gap:2px;
    //   justify-content:center;
    // align-items: center;
      .version-div {
        // justify-content: center;
        // align-items: center;
        font-size: 40px; /* Adjust font size for screens up to 1080px */
        // top:80%;
      }
      .image-container{
        img {
              width: 100%; // Make the image responsive by setting its width to 100%
              height: auto; // Allow the image's height to adjust proportionally
            }
      }
      .description{
        max-width: 450px;
        width:100%;
        height:auto;
        margin:25px  0;
       .image-title{
          font-size: 36px;
        }
        .subtext-div{
          font-size: 18px; 

        }
        ul {
          li {
            margin-bottom: 8px; // Reduce the margin for list items
          }
        }
      }
    }
    .indicator-buttons {
      margin-top: 8px; // Remove margin-top for indicator buttons
    }
  }
  // .right {
    // margin-top: -400px;
    .slider-button.next-button {
      // margin-left: 20px; /* Adjust the margin for the next button */
      width: auto; /* Remove the fixed width for the button container */
    }
  }

  .mobile-version {
    .left,
    .right,
    .mid {
      display: none; // Hide the left, right, and mid components
    }
  }
  }
}

  


@media (max-width: 998px){
  .slider{
    .container{
      margin-top:50px;
      max-width: 100%; 
      .left {
        align-items: center;
        justify-content: center;
        margin-top:-400px;
        .slider-button.prev-button {
          margin-right: 20px; // Adjust the margin for the previous button
        }
      }

      .mid{
        .content{
          justify-content:space-between;
          gap:10px;
          flex-direction: column;

          .version-div{
            justify-content:center;
            font-size: 40px; // Adjust font size for screens below 998px
            transform: none; // Remove vertical transformation
            position: static; // Make it a regular element with no special positioning
            top: auto; // Clear the top positioning
            margin-right: 0; // Remove the right margin
          }
          .image-container{
            img {
              max-width: 100%;
              height: auto;
              width: 260px;
              height:480px;
            }

          }
          .description{
            justify-content:center;
            width:100%;
            height:auto;
            margin:20px  0;
           .image-title{
              font-size: 32px;
            }
            .subtext-div{
              font-size: 18px; 

            }
            ul {
              li {
                margin-bottom: 8px; // Reduce the margin for list items
              }
            }
          }
        }

        .indicator-buttons {
          margin-top: 0; // Remove margin-top for indicator buttons
        }
      }

      .right {
        margin-top:-400px;
        .slider-button.next-button {
          margin-left: 20px; // Adjust the margin for the next button
        }
      }
    }
    .mobile-version {
      .left,
      .right,
      .mid {
        display: none; // Hide the left, right, and mid components
      }
    }
  }
}

@media (max-width: 660px) {
  .slider {
    display:flex;
    align-items: center;
    justify-content:center;
    .container {
      .left,
      .mid,
      .right {
        display: none; // Hide the left, mid, and right components in the container
      }
    }

    .mobile-version {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin:4px;
      margin:10px 0;
      max-width: 100%; // Set a maximum width for the mobile version
      width: 600px; 
      // gap:10px;
      margin-right:140px;
      
      .left,
      .right {
        display: flex; // Show the left and right components in the mobile version
        text-align: center;
        margin-top:-20px;
     
        
        .slider-button.prev-button,
        .slider-button.next-button {
          background-color: #FE5400; // Adjust button background color as needed
          color: #fff; // Adjust button text color as needed
          border: none;
          padding: 5px 10px; // Adjust button padding as needed
          font-size: 16px; // Adjust button font size as needed
          cursor: pointer;
          font-family: 'ClashDisplay-Semibold', sans-serif;
          margin-top: 10px; // Add some top margin as needed

          &:hover {
            color: black; // Change button text color on hover
          }
        }
      }

      .mid {
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content:center;
        .content {
          flex-direction: column; // Stack elements vertically
          align-items: center;
          gap: 10px;

          .top {
            // Style for the top section containing the version
            // font-size: 24px; // Adjust font size as needed
            font-size: 28px;
            font-weight: bold;
            color: white;
            font-family: 'ClashDisplay-Bold', sans-serif;
            display:flex;
            align-items: center;
            justify-content:center;
          }

          .middle {
            display:flex;
            align-items: center;
            justify-content:center;
            img {
              max-width: 100%;
              height: auto;
              width: 260px;
              height:480px;

            }
          }

          .bottom {
            width:320px;
            max-width:100%;
            .image-title {
              display:flex;
              align-items: center;
              justify-content:center;
              font-size: 26px; // Adjust font size as needed
              font-family: 'ClashDisplay-Semibold', sans-serif;
              color:white;
            }

            .subtext-div {
              display:flex;
              align-items: center;
              justify-content:center;
              font-size: 16px; // Adjust font size as needed
              font-family: 'Satoshi-Regular', sans-serif;
              color: #ffba08;
              margin-bottom:2px;
            }

            .image-points{
              justify-content:center;
              align-items:center;
              list-style-type: none;
              padding: 0;
              margin: 0;
              text-align: left;
              font-family: 'Satoshi-Regular', sans-serif;
              color:White;
  
              li {
                margin-bottom: 10px;
              }
            }
          }
        }

        .indicators {
          // Style for the indicator buttons
          margin-top: 10px; // Add some top margin as needed

          .indicator-button {
            background-color: #6f6f6f;
            border: none;
            width: 8px;
            height: 8px;
            margin: 0 5px;
            cursor: pointer;
            border-radius: 50%;
            transition: background-color 0.3s ease;

            &.active {
              background-color: #FE5400;
            }
          }
        }
      }
    }
  }
}

