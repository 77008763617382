.support {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 70px;
  
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
  
      .logoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
  
        .logoImage {
          margin-left: 292px;
          width: 250px;
          height: 250px;
        }
  
        .logoText {
          .maintitle {
            font-size: 100px;
            font-weight: bold;
            color: orangered;
            font-family: 'Raleway', sans-serif;
            position: relative;
  
            &:after {
              content: attr(data-text);
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              color: black;
              -webkit-text-stroke: 4px black;
              font-size: inherit;
              font-weight: inherit;
              font-family: inherit;
              opacity: 0.4;
            }
          }
        }
      }
  
      .content {
        margin-top: 20px;
        h1 {
          font-size: 30px;
          color: #f5f5f5;
          font-family: 'Raleway', sans-serif;
        }
        p {
          font-size: 18px;
          margin-bottom: 15px;
          color: #ccc;
          font-family: 'Raleway', sans-serif;
          a {
            color: #fe5400;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
  
      .connectButton {
        margin-top: 20px;
        .connectbutton {
          background-color: #FE5400;
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 44px;
          cursor: pointer;
          font-weight: 600;
          font-size: 25px;
          margin-top: 20px;
          width: 300px;
          height: 60px;
          font-family: "ClashDisplay-Semibold", sans-serif;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.3s ease; /* Add transition for box-shadow */

          &:hover {
              box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow on hover */
              color: black;
          }
        }
        .link {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  
    @media screen and (max-width: 1080px) {
      .container {
        .logoContainer {
          .logoImage {
            width: 180px;
            height: 180px;
            margin-left: 0;
          }
  
          .logoText {
            .maintitle {
              font-size: 70px;
            }
          }
        }
  
        .content {
          h1 {
            font-size: 24px;
          }
          p {
            font-size: 16px;
          }
        }
  
        .connectButton {
          .connectbutton {
            width: 220px;
            height: 50px;
            font-size: 20px;
          }
        }
      }
    }
  
    @media screen and (max-width: 998px) {
      .container {
        .logoContainer {
          .logoText {
            .maintitle {
              font-size: 60px;
            }
          }
        }
  
        .content {
          h1 {
            font-size: 22px;
          }
          p {
            font-size: 14px;
          }
        }
  
        .connectButton {
          .connectbutton {
            width: 220px;
            height: 45px;
            font-size: 18px;
          }
        }
      }
    }
  
    @media screen and (max-width: 660px) {
      .container {
        .logoContainer {
          .logoImage {
            width: 100px;
            height: 100px;
          }
  
          .logoText {
            .maintitle {
              font-size: 40px;
            }
          }
        }
  
        .content {
          h1 {
            font-size: 20px;
          }
          p {
            font-size: 12px;
          }
        }
  
        .connectButton {
          .connectbutton {
            width: 220px;
            height: 40px;
            font-size: 16px;
          }
        }
      }
    }
  }
  