.footer {
  display: flex;
  justify-content: center;
  background-color: #141414;
  margin-top: auto;

  .container {
    width: 1200px;
    padding: 40px 0px;
    flex-grow: 1;

    hr {
      border: 1px solid #555555;
    }

    .upper-half {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 150px;
      margin: 20px 0px;

      .logo-section {
        display: flex;
        justify-content: center;
        align-items: center;

        .logoimage {
          width: 250px;
          height: 250.92px;
        }

        .logo-name {
          font-size: 50px;
          color: white;
          font-family: 'ClashDisplay-Semibold', sans-serif;
          font-weight: bold;
        }
      }

      .links-section {
        display: flex;
        align-items: center;
        gap: 20px;

        .links-list {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 10px;
          color: #c2c2c2;
          font-family: 'ClashDisplay-Regular';
          text-align: left;
          
          .link {
            text-decoration: none;
            color: inherit;
            font-size: 16px;
            font-family: 'ClashDisplay-Regular';
            position: relative;
            text-align: left;

            &:hover::after {
              content: '';
              position: absolute;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #fe5400;
            }
          }
        }
      }
    }

    .lower-half {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 10px;
      gap: 620px;

      .left-section {
        width: max-content;
        display: flex;
        align-items: center;
        gap: 20px;
        font-family: 'ClashDisplay-Regular';


        p {
          font-size: 16px;
          white-space: nowrap;
          color: #6e6e6e;
          font-family: 'ClashDisplay-Regular';
          text-align:left;
        }
      }

      .right-section {
        width: max-content;
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: -50px;

        .social {
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 1080px) {
    .container {
      padding: 20px 0px;

      .upper-half{
        .links-section{
          padding-right: 10px;
        }
      }
    }
  }

  @media (max-width: 990px) {
    .container{
      gap:25px;
      .upper-half {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
        // gap:10px;
  
        .logo-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 0px;
  
          .logoimage {
            width: 220px;
            height: 220px;
          }
  
          .logo-name {
            font-size: 36px;
          }
        }
  
        .links-section {
          margin-top: -120px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;
          text-align: left;
        }
      }
      .lower-half{
          gap:300px;
      }
    }
  }

  @media (max-width: 660px) {
    .container {
      .upper-half {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;

        .logo-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 0px;

          span {
            font-size: 32px;
          }
        }

        .links-section {
          margin-top: -120px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: flex-start;
        }
      }

      .lower-half {
        gap: 10px;
        align-items: center;
        justify-content: space-around;

        .left-section {
          margin-right: 50px;
        }

        .social {
          margin-left: 50px;
        }
      }
    }

    hr {
      width: 80%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
