.final {
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color:white;
  margin-bottom:70px;
  .container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1392px;
      height: 750px;
      flex-shrink: 0;
      border-radius: 32px;
      background: #262626;
      padding: 20px;

      .left-div {
          display: flex;
          flex-direction: column;
          gap: 2px;
          justify-content: space-between;

          .train1,
          .train2 {
              color: #6F6F6F;
              font-family: "ClashDisplay-Semibold", sans-serif;
              font-size: 64px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: 3.84px;
              text-transform: uppercase;
              margin-bottom: 0px;
          }

          .train1 {
              margin-bottom: -65px;
          }

          .pavanputra {
              font-size: 80px;
              margin-top: -15px;
              font-family: "ClashDisplay-Semibold", sans-serif;
              -webkit-text-stroke: 1px white;
              color: transparent;
              position: relative;
              margin-bottom: 0px;

              &::before {
                  content: attr(data-text);
                  position: absolute;
                  top: 0;
                  left: 0;
                  -webkit-text-stroke: none;
                  color: transparent;
                  z-index: -1;
              }
          }

          .colored-line {
              width: 670px;
              height: 15px;
              flex-shrink: 0;
              background: #FE5400;
          }

          p {
              width: 570px;
              color: #C2C2C2;
              font-family: 'Satoshi-Medium', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin-bottom: 40px;
          }

          .getstartedbutton {
              background-color: #FE5400;
              color: white;
              border: none;
              padding: 10px 20px;
              border-radius: 44px;
              cursor: pointer;
              font-weight: 600;
              font-size: 25px;
              margin-top: 20px;
              width: 230px;
              height: 50px;
              font-family: "ClashDisplay-Semibold", sans-serif;
              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
              transition: box-shadow 0.3s ease;
              .getstartedbutton:hover {
                  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow on hover */
                  color: black;
                }

              .link {
                  text-decoration: none;
                  color: white;
              }
          }

      }

      .right-div {
          img {
              display: flex;
              width: 469px;
              height: 449px;
              padding: 0px 0.003px 0px 0px;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              max-width: 100%; /* Make the image scale down to fit its container */
              height: auto; 
          }
      }
  }

  @media (max-width: 1450px) {
      .container {
          width: 90%;
          height: auto;
      }

      .left-div {
          .train1,
          .train2 {
              font-size: 60px;
          }

          .pavanputra {
              font-size: 70px;
          }

          .colored-line {
              max-width:70%;
          }

          p {
              font-size: 16px;
              max-width:70%;
              margin-bottom:25px;
          }

          .getstartedbutton {
              width: 200px;
              height: 50px;
              font-size: 20px;
          }
          .getstartedbutton:hover {
              box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow on hover */
              color: black;
            }
      }
      .right-div{
              align-items:center;
              justify-content:center;
              max-width: 90%;
              padding:20px 0;
              img {
                  width: 100%; /* Make the image scale to fit its container's width */
                  height: auto; /* Allow the height to adjust proportionally */
              }
      }
  }

  
  @media (min-width: 661px) and (max-width: 998px) {
      .container {
          width: 95%;
          // flex-direction: column-reverse;
          align-items: center;
          justify-content: center;
  
          .left-div {
              // padding-left: 25px;
              width:80%;
  
              .train1 {
                  width:80%;
                  font-size: 50px;
                  margin: 0.5px 0;
              }
  
              .train2 {
                  width:80%;
                  margin-top: -1px;
                  font-size: 50px;
              }
  
              .pavanputra {
                  width:80%;
                  font-size: 60px;
              }
  
              .colored-line {
                  // max-width: 70%;
              }
  
              p {
                  font-size: 16px;
                  max-width: 70%;
                  margin-bottom: 25px;
              }
  
              .getstartedbutton {
                  width: 200px;
                  height: 50px;
                  font-size: 16px;
              }
              .getstartedbutton:hover {
                  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow on hover */
                  color: black;
                }
          }
  
          .right-div {
              align-items: center;
              justify-content: center;
              // max-width: 90%;
              margin-left:-80px;
              img{
                  max-width: 100%; /* Make the image scale to fit its container's width */
                  height: auto; /* Allow the height to adjust proportionally */
                  width:270px;
                  height:270px;
                   
              }
          }
      }
  }

  @media (max-width: 660px) {
      .container {
          width: 95%;
          flex-direction:column-reverse;
          align-items:center;
          justify-content:center;
          .left-div{
              padding-left: 34px; /* Restore left padding */
              gap: 1px;
              text-align: left; /* Align content to the left */
              display: flex;
              flex-direction: column;
              align-items: flex-start; 
              .train1,
              .train2{
                  margin:0.5px 0;
                  font-size: 35px;
              }
              .train2{
                  margin-top:-1px;
              }
              .pavanputra{
                  font-size: 45px;
              }
              .colored-line{
                  width:335px;
                  height:7.5px;
                  max-width: 90%;
              }
              p{ 
                  width:100%;
                  font-weight: 12px;
                  margin-bottom:18px;
              }
              .getstartedbutton{
                  width: 200px;
                  height: 50px;
                  font-size: 16px;
                  margin-bottom:20px;
              
              }
              .getstartedbutton:hover {
                  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow on hover */
                  color: black;
                }
          }

          .right-div{
              align-items:center;
              justify-content:center;
              max-width: 65%;
              margin-top:20px;
              img {
                  max-width: 100%; /* Make the image scale to fit its container's width */
                  height: auto; /* Allow the height to adjust proportionally */
                  width:260px;
                  height:260px;
              }
          }
      }
  }
}
