.block4 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  text-align: center;
  margin: 40px 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .pricing {
      margin-bottom: 20px;
      justify-content: space-around;
      align-items: center;
      display: flex;

      .pricing-text {
        color: #C2C2C2;
        font-size: 56px;
        margin-bottom: 50px;
        font-weight: bold;
        font-family: "ClashDisplay-Semibold", sans-serif;
      }
    }

    .plans {
      display: flex;
      justify-content: space-evenly;
      gap: 20px;

       .plan1, .plan2, .plan3 {
        align-items: center;
        justify-content: center;
        width: 250px;
        height: 350px;
        background-color: transparent;
        border: 1px solid #393D44;
        border-radius: 16px;
        color: #fff;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease-in-out;
        text-align: left;
        padding-top: 20px;
        padding-bottom: 20px;
        font-family: 'Raleway', sans-serif;

        &:hover {
          transform: scale(1.05);
          border-color: white;
        }

        span {
          margin-top: 30px;
          font-size: 18px;
          font-weight: bold;
          font-family: "ClashDisplay-Semibold", sans-serif;

          &.basic { // Add this class selector for Basic plan
            color: #FBC628;
          }
  
          &.standard { // Add this class selector for Standard plan
            color: #03D17C;
          }
  
          &.premium { // Add this class selector for Premium plan
            color: #00A4F8;
          }
        }

        h1 {
          font-size: 36px;
          color: white;
          margin: 10px 0;
          font-weight: bold;
          font-family: "ClashDisplay-Semibold", sans-serif;

          span {
            font-size: 18px;
            font-weight: normal;
          }
        }

        ul {
          text-align: left;
          list-style-type: none;
          padding: 0;
          margin: 10px 0;

          li {
            display: flex;
            align-items: center;
            margin: 5px 0;
            color: white;
            gap: 10px;
            font-family: 'Satoshi-Medium', sans-serif;
          }

          .notavailable {
            color: #6F6F6F;
          }
          
          .highlighting {
            color: #ffba08;
          }
        }

        button {
          margin: 15px 0px;
          background-color: transparent;
          color: #fff;
          border: 1px solid #FE5400;
          border-radius: 20px;
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;
          font-family: 'ClashDisplay-Regular';

          &:hover {
            background-color: #FE5400;
            color: black;
          }

          span {
            font-weight: bold;
          }

          .link{
            text-decoration:none;
            color:white;
          }
        }
      }
    }
  }
}

// Media query for 1080px screens
@media (max-width: 1350px) {
  .block4{
    .wrapper{
      .pricing{
        .pricing-text{
          // font-size: 48px;
          }
      }
      .plan-cards{
        flex-wrap: wrap;
        // justify-content: space-evenly;
        gap:5px;

        .plan,
        .plan1,
        .plan2,
        .plan3 {
          width:240px;
        }

      }
    }
   } 
 
}

// Media query for 998px screens
@media (max-width: 940px) {
  .block4{
          .wrapper{
            .pricing{
              .pricing-text{
          font-size: 48px;
          }
      }
      .plan-cards{
        flex-wrap: wrap;
        // justify-content: space-evenly;

        .plan,
        .plan1,
        .plan2,
        .plan3 {
          // width: 48%; /* Set the width to 48% for two cards per row */
        }

      }
    }
   }
}

// Media query for 660px screens
@media (max-width: 660px) {
  .block4{
    .wrapper{
      .pricing{
        .pricing-text{
        font-size: 38px;
        }
    }
    .plan-cards{
      flex-direction:column;
      .plan,.plan1,.plan2,.plan3{

      }
    }
  }
 }
}
