.pageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
    .formContainer {
      text-align: center;
  
      .formWrapper {
        .logo {
          display: flex;
          align-items: center;
  
          .logoImage {
            width: 250px;
            height: 250px;
          }
  
          .logoText {
            font-family: 'Raleway', sans-serif;
            font-size: 70px;
            margin-top: -5px;
  
            .maintitle {
              font-size: 100px;
              font-weight: bold;
              color: orangered;
              font-family: 'Raleway', sans-serif;
              position: relative;
  
              &:after {
                content: attr(data-text);
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                color: black;
                -webkit-text-stroke: 4px black;
                font-size: inherit;
                font-weight: inherit;
                font-family: inherit;
                opacity: 0.4;
              }
            }
          }
        }
  
        .subtitle {
          margin-top: -5px;
          font-family: "Raleway", sans-serif;
          font-weight: 400;
  
          span {
            font-size: 20px;
          }
        }
  
        .Connect {
          margin-top: 20px;
  
          .Connect-button {
            width: 250px;
            height: 65px;
            background-color: #fe5400;
            color: white;
            border: none;
            box-shadow: 0px 0px 10px rgba(254, 84, 0, 0.5);
            transition: box-shadow 0.3s ease;
            z-index: 3;
            font-family: 'Raleway', sans-serif;
            font-size: 26px;
            border-radius: none;
            cursor: pointer;
  
            &:hover {
              color: black;
              box-shadow: 0px 0px 15px rgba(254, 84, 0, 0.7);
            }
          }
        }
      }
    }
  
    @media (max-width: 1080px) {
      .formContainer {
        margin: 0px 40px;
  
        .formWrapper {
          .logo {
            .logoImage {
              width: 200px;
              height: 200px;
            }
  
            .logoText {
              .maintitle {
                font-size: 90px;
              }
            }
          }
  
          .subtitle {
            span {
              font-size: 16px;
            }
          }
  
          .Connect {
            .Connect-button {
              width: 200px;
              height: 60px;
              font-size: 20px;
            }
          }
        }
      }
    }
  
    @media (max-width: 998px) {
      .formContainer {
        margin: 0px 40px;
  
        .formWrapper {
          .logo {
            .logoImage {
              width: 180px;
              height: 180px;
            }
  
            .logoText {
              .maintitle {
                font-size: 80px;
              }
            }
          }
  
          .subtitle {
            span {
              font-size: 16px;
            }
          }
  
          .Connect {
            .Connect-button {
              width: 180px;
              height: 45px;
              font-size: 18px;
            }
          }
        }
      }
    }
  
    @media (max-width: 660px) {
      .formContainer {
        .formWrapper {
          .logo {
            .logoImage {
              width: 100px;
              height: 100px;
            }
  
            .logoText {
              .maintitle {
                font-size: 40px;
              }
            }
          }
  
          .subtitle {
            span {
              font-size: 14px;
            }
          }
  
          .Connect {
            .Connect-button {
              width: 200px;
              height: 50px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  