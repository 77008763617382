/* block3.scss */
.block3 {
  background-color: #141414;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 380px;
}

.line {
  font-size: 36px; /* Decrease font size for responsiveness */
  font-weight: semibold;
  color: #fffcfc;
  text-align: center;
  margin-bottom: 10px;
  font-family: "ClashDisplay-Semibold", sans-serif;
  max-width: 90%; /* Limit the maximum width to maintain readability */
}

.line2 {
  font-size: 36px; /* Decrease font size for responsiveness */
  font-weight: bold;
  background-color: #fe5400;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  font-family: "ClashDisplay-Semibold", sans-serif;
  max-width: 90%; /* Limit the maximum width */
}

.join-button {
  background-color: #FE5400;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 44px;
  cursor: pointer;
  font-weight: 600;
  font-size: 25px;
  margin-top: 20px;
  width: 250px;
  height: 60px;
  font-family: "ClashDisplay-Semibold", sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease; /* Add transition for box-shadow */
  
  &:hover {
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow on hover */
    color: black;
  }

  .link {
    text-decoration: none;
    color: inherit;
  }
}

// Media Queries
@media (max-width: 1080px) {
  .line,
  .line2 {
    font-size: 30px; /* Adjust font size */
  }
}

@media (max-width: 998px) {
  .line,
  .line2 {
    font-size: 26px; /* Adjust font size */
  }
}

@media (max-width: 660px) {
  .line,
  .line2 {
    font-size: 20px; /* Adjust font size */
  }
  .join-button{
    width:230px;
    height:60px;
  }
}
