.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c2c2c2;
  position: sticky;
  top: 0;
  z-index: 999;
  transition: 0.5s all ease;
  background-color: rgba(29, 29, 29, 0.8);

  .container {
    width: 90%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;

    .logo {
      align-items: center;

      .logoimage {
        width: 70px;
        height: 73.26px;
      }
    }

    .hamburger-menu {
      display: none;

      button {
        background: none;
        border: none;
        cursor: pointer;
        color: #fff;
        font-size: 20px;
      }
    }

    .navlinks {
      display: flex;
      align-items: center;
      gap: 24px;
      font-weight: 500px;

      ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        gap: 24px;
        font-weight: 500px;

        li {
          .link {
            text-decoration: none;
            color: inherit;
            font-size: 16px;
            font-family: 'ClashDisplay-Regular';
            position: relative;
            &:hover::after {
              content: "";
              position: absolute;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #fe5400;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1080px) {
    .container {
      padding: 10px;
    }
  }

  @media (max-width: 998px) {
    .container {
      flex-direction: row;
      justify-content: space-between;

      .logo {
        width: auto;
        margin-right: auto;
      }

      .hamburger-menu {
        display: block;
        button {
          display: block;
        }
      }

      .navlinks {
        display: none;
      }

      .navlinks.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
      //   background-color: rgba(40, 40, 40, 0.8);
      background-color: rgba(20, 20, 20, 0.9);
        padding: 10px 0;

        ul {
          // background-color: rgba(29, 29, 29, 0.9); /* Lighter shade of black */
          // width: 100vw; 
          // background-color: #fff;
          list-style: none;
          flex-direction:column !important;
          margin: 0;
          padding: 0;

          li {
              // background-color: #fff;
              // width: 100%;
            .link {
              // background-color:white;
              text-decoration: none;
              color: inherit;
              font-size: 16px;
              font-family: 'ClashDisplay-Regular';
              position: relative;
              &:hover::after {
                content: "";
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #fe5400;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 660px) {
    .navlinks {
      ul {
        li {
          .link {
            font-size: 14px;
          }
        }
      }
    }
  }
}
