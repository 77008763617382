.pageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .formContainer {
       text-align: center;

    .formWrapper {
      .logo {
        display: flex;
      //   flex-direction: column;
        align-items: center;

        .logoImage {
          width: 250px;
          height: 250px;
        }

        .logoText {
          font-family: 'Raleway', sans-serif;
          font-size: 70px;
          margin-top: -5px;

          .maintitle {
            font-size: 100px;
            font-weight: bold;
            color: orangered;
            font-family: 'Raleway', sans-serif;
            position: relative;

            &:after {
              content: attr(data-text);
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              color: black;
              -webkit-text-stroke: 4px black;
              font-size: inherit;
              font-weight: inherit;
              font-family: inherit;
              opacity: 0.4;
            }
          }
        }
      }

      .subtitle {
          margin-top:-5px;
          display: flex;
          flex-direction: column;
          font-family:"Raleway",sans-serif;
          font-weight: 400;
          padding:12px 0;

          span{
               font-size: 20px;
               color:white;
          }
      }

      .login {
        margin-top: 20px;

        .login-button {
          background-color: #FE5400;
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 44px;
          cursor: pointer;
          font-weight: 600;
          font-size: 25px;
          margin-top: 20px;
          width: 400px;
          height: 60px;
          font-family: "ClashDisplay-Semibold", sans-serif;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.3s ease; /* Add transition for box-shadow */

          &:hover {
              box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow on hover */
              color: black;
          }
        }
      }
    }
  }
}

@media (max-width:1080px){
  .pageContainer{
      .formContainer{
           margin: 0px 40px;
          .formWrapper{
             
              .logo
              {
                  .logoImage{
                      width: 200px;;
                      height: 200px;
                  }

                  .logoText{
                      .maintitle{
                         font-size:90px;
                      }
                  }
              }

              .subtitle{
                  span{
                      font-family:16px;
              }
          }

          .login{
              .login-button{
                   width: 200px;
        height: 60px;
        font-size: 20px;
              }
          }
      }
  }
}
}
@media (max-width:998px){
  .pageContainer{
      .formContainer{
           margin: 0px 40px;
          .formWrapper{
             
              .logo
              {
                  .logoImage{
                      width: 180px;
                      height: 180px;
                  }

                  .logoText{
                      .maintitle{
                         font-size:80px;
                      }
                  }
              }

              .subtitle{
                  span{
                      font-family:18px;
              }
          }

          .login{
              .login-button{
                   width: 200px;
        height: 50px;
        font-size: 20px;
              }
          }
      }
  }
}
}


@media (max-width:660px){
  .pageContainer{
      .formContainer{
          .formWrapper{
              .logo
              {
                  .logoImage{
                      width: 100px;
                      height: 100px;
                  }

                  .logoText{
                      .maintitle{
                         font-size:40px;
                      }
                  }
              }

              .subtitle{
                  span{
                      // font-family:1px;
              }
          }

          .login{
              .login-button{
                  width: 200px;
                  height: 50px;
                  font-size:20px;
              }
          }
      }
  }
}
}